@import '@/styles/mixins';

.video-container {
    position: relative;
    padding-bottom: 48.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}

.video-container embed,
.video-container iframe,
.video-container object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 600px) {
    .video-container embed,
    .video-container iframe,
    .video-container object {
        margin: auto 0;
    }
}
