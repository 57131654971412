@import '@/styles/mixins.scss';

.wrapper {
    @include flex-column;

    --card-width: 262px;

    min-width: var(--card-width);

    &.enter {
        .image {
            opacity: 1;
            transition: 150ms ease-out;
        }

        .name-wrapper {
            opacity: 1;
            transform: translateY(0);
            transition: 150ms ease-out;
            transition-delay: 200ms;
        }
    }
}

.image {
    border-radius: 16px;
    min-width: var(--card-width);
    min-height: var(--card-width);

    background-color: var(--colors-gray-light-90);
    border-radius: 16px;
}

.content-wrapper {
    @include flex-column;
    padding: 32px 16px;
}

.name-wrapper {
    @include flex-column;
    opacity: 0.01;
    transform: translateY(-20px);
    gap: 12px;
}

.name {
    @include caption-L;
    color: var(--colors-primary-dark-90);
    margin: 0;
}

.role {
    @include text-S;
    color: var(--colors-primary);
}

.bio {
    @include text-S;
    color: var(--colors-gray-dark-30);
    margin: 0;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.hidden-bio {
    @include text-S;
    color: transparent;
    margin: 0;

    user-select: none;
}

@media screen and (max-width: 1280px) {
    .wrapper {
        --card-width: 264px;
    }
}

@media screen and (max-width: 960px) {
    .wrapper {
        --card-width: 264px;
    }
}

@media screen and (max-width: 764px) {
    .wrapper {
        --card-width: 210px;
    }
}

@media screen and (max-width: 524px) {
    .wrapper {
        --card-width: 300px;
    }
}
