@import '@/styles/mixins.scss';

.wrapper {
    position: relative;
    @include flex-column;
    gap: 20px;
    padding: 80px;
    background: var(--colors-mono-white-opacity-100, #fff);
    border-radius: 16px;
    border: 1px solid var(--colors-mono-black-opacity-5, rgba(0, 0, 0, 0.05));
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.05);
}

.quote-icon {
    position: absolute;
    width: 220px;
    height: 220px;
    left: 1px;
    top: 1px;
    z-index: 1;
    fill: var(--colors-primary);
    opacity: 0.04;
}

.name {
    @include headline-6;
    color: var(--colors-primary);
}

.row {
    @include flex-row;
    gap: 52px;
}

.section-wrapper {
    @include flex-column;
    gap: 40px;
}

.header {
    @include flex-column;
    gap: 24px;
}

.title {
    @include headline-2;
    color: var(--colors-primary-dark-90);
}

.content {
    @include text-L;
    color: var(--colors-gray-dark-40);
    font-weight: 300;

    p {
        margin: 0;
    }
}

.points {
    position: relative;
    @include flex-column;
    gap: 24px;
}

.vl {
    position: absolute;
    width: 1px;
    height: 100%;
    left: 16px;
    background: var(--colors-primary-light-90, #e8f1fd);
    z-index: 1;
}

.image-and-points {
    @include flex-row;
}

.points-wrapper {
    @include flex-column;
    gap: 40px;
}

.point {
    @include flex-row;
    align-items: center;
    gap: 16px;
}

.svg-wrap {
    @include flex-center;
    background: var(--colors-primary-light-90, #e8f1fd);
    border-radius: 50%;
    z-index: 2;
    flex-shrink: 0;
    width: 32px;
    height: 32px;

    svg {
        width: 20px;
        height: 20px;
        stroke: var(--colors-primary);
    }
}

.number {
    @include flex-center;
    width: 32px;
    height: 32px;
    background: var(--colors-primary-light-90, #e8f1fd);
    border-radius: 50%;
    color: var(--colors-primary);
    z-index: 2;
    flex-shrink: 0;
}

.text {
    @include headline-5;
    color: var(--colors-gray-dark-50);
}

.image-wrap {
    position: relative;
    cursor: pointer;

    &:hover {
        .play-icon {
            transform: scale(1.5);
            top: calc(50% - 16px);
            left: calc(50% - 16px);
        }
    }
}

.play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    height: 32px;
    width: 32px;
    fill: white;
    stroke: white;

    transition: 100ms ease-out;
}

.smaller-image {
    display: none;
}

.buttons {
    @include flex-row;
    gap: 16px;
}

.shadow {
    box-shadow: 0 0 0 4px var(--colors-primary-light-70, #b9d4fa), 0 0 0 8px var(--colors-primary-light-90, #b9d4fa) !important;

    transition: 150ms ease-in-out;

    opacity: 1;

    padding: 10px 20px;

    &:hover {
        box-shadow: 0 0 0 5px var(--colors-primary-light-70, #b9d4fa),
            0 0 0 10px var(--colors-primary-light-90, #b9d4fa) !important;
    }
}

.image {
    width: 390px;
}

@media screen and (max-width: 1280px) {
    .wrapper {
        padding: 60px;
    }

    .image {
        width: 280px;
        height: 390px;
    }
}

@media screen and (max-width: 960px) {
    .image {
        display: none !important;
    }

    .image-and-points {
        justify-content: space-between;
    }

    .buttons {
        @include flex-column;
        gap: 16px;
    }

    .smaller-image {
        display: block;
        width: 280px;
        height: 391px;
    }
}

@media screen and (max-width: 764px) {
    .wrapper {
        padding: 52px 40px;
        gap: 16px;
    }

    .header {
        gap: 16px;
    }

    .image-and-points {
        @include flex-column;
        flex-direction: column-reverse;
        gap: 40px;
    }

    .smaller-image {
        margin: auto;
    }
}

@media screen and (max-width: 524px) {
    .container {
        padding: 80px 16px;
    }
    .wrapper {
        padding: 52px 24px;
        gap: 16px;
    }

    .image-wrap {
        position: relative;
        @include flex-center;

        svg {
            position: absolute;
            display: block;
            width: 32px;
            height: 32px;
            z-index: 2;
        }
    }

    .smaller-image {
        display: inline;

        margin: 0;

        width: 240px;
        height: 335px;
    }
}
