@import '@/styles/mixins.scss';

.wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 40px;

    &.left {
        > *:first-child {
            order: 1;
        }

        .title {
            transform: translateX(80px);

            &.enter {
                opacity: 1;
                transform: translateX(0);
                transition: 150ms ease-out;
            }
        }

        .image-background {
            transform: translateX(-80px);
            width: 100%;

            &.enter {
                opacity: 1;
                transform: translateX(0);
                transition: 150ms ease-out;
                transition-delay: 200ms;
            }
        }
    }

    .title {
        @include headline-1;
        transform: translateX(-80px);
        margin: 0;
        opacity: 0.01;

        &.enter {
            opacity: 1;
            transform: translateX(0);
            transition: 150ms ease-out;
        }
    }

    .description {
        @include text-L;
        color: var(--colors-gray-dark-30);
        opacity: 0.01;

        &.enter {
            opacity: 1;
            transition: 150ms ease-out;
            transition-delay: 300ms;
        }
    }

    .image-background {
        @include flex-center;
        position: relative;
        border-radius: 16px;
        overflow: hidden;
        transform: translateX(80px);
        opacity: 0.01;
        height: 100%;
        width: 100%;

        &.enter {
            opacity: 1;
            transform: translateX(0);
            transition: 150ms ease-out;
            transition-delay: 200ms;
        }
    }
}

.button {
    opacity: 0.01;

    &.enter {
        opacity: 1;
        transition: 150ms ease-out;
        transition-delay: 400ms;
    }
}

.text-wrapper {
    @include flex-column;
    gap: 24px;
}

.buttons {
    @include flex-row;
    gap: 10px;
    margin-top: 35px;
}
.content-box {
    margin-right: 10%;
}

.image {
    object-fit: contain;
    width: 100% !important;
    position: relative !important;
    border-radius: 16px;
    height: unset !important;
    max-height: 500px;
}

@media screen and (max-width: 960px) {
    .wrapper {
        grid-template-columns: 1fr;
        gap: 24px;

        &.left {
            grid-template-columns: 1fr;
        }
    }
    .content-box {
        margin-right: 0;
    }

    .image-background {
        width: 100%;
    }

    .image {
        object-position: center;
    }
}

@media screen and (max-width: 524px) {
    .buttons {
        flex-direction: column;
        gap: 10px;
    }
}
