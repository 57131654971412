@import '/styles/mixins';

/* The switch - the box around the slider */
.switch {
    @include subtitle-M;
    position: relative;
    display: inline-block;
    width: 36px;
    height: 20px;
    flex-shrink: 0;

    input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked {
            + .slider:before {
                -webkit-transform: translateX(16px);
                -ms-transform: translateX(16px);
                transform: translateX(16px);
            }

            + .slider {
                background-color: var(--colors-primary);

                &:hover {
                    background-color: var(--colors-primary);
                }
            }
        }
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--colors-gray-light-90);
        -webkit-transition: 150ms ease-out;
        transition: 150ms ease-out;
        outline: 0;

        &:hover {
            background-color: var(--colors-gray-light-50);
            color: var(--colors-gray-dark-60);
        }

        &:before {
            box-shadow: 0px 3px 1px 0 rgba(16, 24, 40, 0.1), 0px 2px 1px 0 rgba(16, 24, 40, 0.06);
            position: absolute;
            content: '';
            height: 16px;
            width: 16px;
            left: 2px;
            bottom: 2px;
            background-color: white;
            -webkit-transition: 150ms ease-out;
            transition: 150ms ease-out;
            z-index: 2;
        }

        &.round {
            border-radius: 28px;

            &:before {
                border-radius: 50%;
            }
        }
    }

    &.disabled {
        opacity: 0.5;

        &.slider {
            cursor: default;
        }
    }
}
