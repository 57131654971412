@import '@/styles/mixins.scss';

.wrapper {
    @include flex-column;
    gap: 20px;

    margin-top: 42px;
}

.feature {
    border-radius: 16px;
    border: 1px solid var(--colors-mono-black-opacity-5, rgba(0, 0, 0, 0.05));
    background: var(--colors-mono-white-opacity-100, #fff);
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.05);

    transition: 150ms ease-out;

    transform: translateY(50px);
    opacity: 0.01;

    &.enter {
        opacity: 1;
        transform: translateY(0);
    }
}

.header {
    @include flex-column;
    gap: 12px;

    padding: 32px 24px 24px;

    &:hover {
        cursor: pointer;
    }

    span {
        @include headline-6;
        color: var(--colors-gray-dark-40);
        margin: 0;
    }

    p {
        @include text-S;
        color: var(--colors-gray-dark-40);
        margin: 0;
    }
}

.title-wrap {
    @include flex-row;
    gap: 16px;
    justify-content: space-between;
    align-items: center;

    svg {
        stroke: var(--colors-gray-dark-40);
        width: 24px;
        height: 24px;
    }
}

.value {
    @include flex-row;

    border-top: 1px solid var(--colors-mono-black-opacity-5);

    &.taxd {
        background: var(--colors-primary-light-95);
    }
}

.item {
    @include flex-row;
    @include headline-6;

    flex-grow: 1;
    gap: 8px;

    height: 64px;
    padding: 20px;

    color: var(--colors-mono-black-opacity-90);

    .item-icon {
        height: 20px;
        width: 20px;
    }

    .item-logo {
        height: 24px;
    }
}

.status {
    @include flex-center;

    height: 64px;
    width: 64px;
    padding: 12px;

    border-left: 1px solid var(--colors-mono-black-opacity-5);

    svg {
        width: 24px;
        height: 24px;
    }
}
