@import '@/styles/mixins.scss';

.section {
    width: 100%;
    position: relative;
    overflow: hidden;
}

.wrap {
    max-width: 1440px;
    padding: 0 80px;
    margin: 0 auto;
}

@media screen and (max-width: 1280px) {
    .wrap {
        padding: 0 60px;
    }
}

@media screen and (max-width: 960px) {
    .wrap {
        padding: 0 60px;
    }
}

@media screen and (max-width: 764px) {
    .wrap {
        padding: 0 40px;
    }
}

@media screen and (max-width: 524px) {
    .wrap {
        padding: 0 24px;
    }
}
