@import '@/styles/mixins.scss';

.icon-text-box {
    @include flex-center;
    justify-content: flex-start;
    gap: 16px;
    padding: 10px 16px;
    background: var(--colors-primary-light-90);
    border-radius: 8px;
    border: 1px solid var(--colors-primary);
    opacity: 0.01;
    transform: translateX(40px);
    transition: 150ms ease-out;

    &.enter {
        opacity: 1;
        transform: translateX(0);
        transition-delay: 200ms;
    }

    .icon {
        opacity: 0.01;
        transform: translateX(-30px);
        transition: 150ms ease-out;

        width: 20px;
        height: 20px;

        &.enter {
            opacity: 1;
            transform: translateX(0);
            transition-delay: 300ms;
        }
    }

    .content {
        @include text-M;
        font-weight: 500;
        align-self: center;
        color: var(--colors-primary);
        margin: 0;

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .hidden-content {
        @include text-M;

        color: transparent;
        user-select: none;
    }
}
