@import '@/styles/mixins.scss';

.section-wrap {
    opacity: 0.01;
    transform: translateY(60px);

    &.enter {
        opacity: 1;
        transform: translateY(0px);
        transition: 150ms ease-out;
    }
}

.wrapper {
    @include flex-column;
    padding: 80px;
    gap: 40px;
    max-width: 1220px;
    border-radius: 16px;
    border: 1px solid var(--colors-mono-black-opacity-5, rgba(0, 0, 0, 0.05));
    background: var(--colors-mono-white-opacity-100, #fff);
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.05);
}

.section-title {
    padding-top: 0 !important;
}

.contact-title {
    @include headline-2;
    color: var(--colors-primary-dark-90);
    margin: 0;
}

.content-wrapper {
    @include flex-row;
    gap: 52px;
    justify-content: space-between;
}

.content {
    @include flex-column;
    gap: 52px;
}

.subtitle {
    @include headline-4;
    color: #175cd3;
    margin: 0;
}

.title {
    @include headline-1;
    color: var(--colors-primary-dark-90);
    margin: 0;
}

.description {
    @include text-L;
    color: #667085;

    p {
        margin: 0;
    }

    span {
        cursor: pointer;
        color: var(--colors-primary);
        text-decoration: underline;
    }
}

.contact-form {
    @include flex-column;
    justify-content: space-between;
    gap: 32px;
}

.complete-wrapper {
    @include flex-column;
    gap: 20px;
    width: 100%;
    height: 100%;
}

.grid {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 20px 16px;
    width: 504px;
}

.form-group {
    @include flex-column;

    &.message {
        grid-column: 1 / span 2;
        textarea {
            @include text-S;
            height: 144px !important;
            resize: none;
            padding: 8.5px 13px;
            color: var(--colors-gray-dark-0, #98a2b3);
        }
    }
}

.label {
    @include subtitle-S;
    color: var(--colors-gray-dark-40);
    margin-bottom: 6px;
}

.input {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    background-color: #ffffff;
    border-radius: 8px;
    border: var(--counter, 1px) solid var(--colors-mono-black-opacity-10, rgba(0, 0, 0, 0.1));
    width: 100% !important;
    line-height: 44px;
    padding: 0 13px;
    margin-bottom: 0;
    outline: none;
    transition: 150ms ease-out box-shadow;

    &::placeholder {
        @include text-M;
        color: var(--colors-gray);
    }

    &:focus {
        border: 1px solid #84caff;
        -webkit-box-shadow: 0px 0px 0px 4px #d1e9ff;
        -moz-box-shadow: 0px 0px 0px 4px #d1e9ff;
        box-shadow: 0px 0px 0px 4px #d1e9ff;
    }

    &:invalid:not(:placeholder-shown) {
        border: 1px solid #fda29b;
    }

    &:invalid:not(:placeholder-shown):focus {
        -webkit-box-shadow: 0px 0px 0px 4px #fee4e2;
        -moz-box-shadow: 0px 0px 0px 4px #fee4e2;
        box-shadow: 0px 0px 0px 4px #fee4e2;
    }
}

.asterisk {
    color: var(--colors-error);
    margin-left: 2px;
    font-weight: bold;
}

.submit {
    @include flex-center;
    @include cta-primary;
    color: var(--colors-mono-white-opacity-100, #fff);
    display: inline-flex;
    height: 45px;
    width: 124px;
    text-align: center;
    padding: 9px 19px;
    font-weight: 600;
    white-space: nowrap;
    user-select: none;
    cursor: pointer;
    text-decoration: none;
    border: 1px solid transparent;
    transition: 150ms ease-out;
    border-radius: 8px;
    background: var(--colors-primary-dark-0, #1570ef);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);

    &:hover {
        background: var(--colors-primary-light-20);
        box-shadow: 0px 8px 10px 0px #1570ef80;
    }
}

.info-block {
    @include flex-row;
    gap: 20px;
}

.info-item {
    @include flex-column;
    gap: 12px;

    .label {
        @include headline-5;
        color: var(--colors-primary);
    }

    .description {
        @include text-L;
        color: var(--colors-gray-dark-30);
    }
}

.svg-wrap {
    @include flex-center;
    padding: 0 12px;
    height: 36px;
    width: 36px;
    border-radius: 29px;
    background: var(--colors-primary-light-90, #e8f1fd);

    svg {
        stroke: var(--colors-primary);
        width: 20px;
        height: 20px;
        flex-shrink: 0;
    }
}

/* starting ENTER animation */
.image-enter {
    opacity: 0.01;
}

/* ending ENTER animation */
.image-enter-active {
    transition-delay: 200ms;
    transition: 200ms ease-out;
    opacity: 1;
}

/* starting EXIT animation */
.image-exit {
    opacity: 1;
}

/* ending EXIT animation */
.image-exit-active {
    transition-delay: 200ms;
    transition: 200ms ease-out;
    opacity: 0.01;
}

@media screen and (max-width: 1280px) {
    .wrapper {
        padding: 60px;
    }

    .content-wrapper {
        gap: 40px;
    }

    .grid {
        grid-gap: 16px;
        grid-template-columns: auto;
        max-width: 400px;
    }

    .form-group {
        &.message {
            grid-column: unset;
        }
    }
}

@media screen and (max-width: 960px) {
    .content-wrapper {
        @include flex-column;
        gap: 60px;
    }

    .grid {
        width: unset;
        max-width: unset;
        grid-template-columns: auto auto;
        grid-gap: 20px 16px;
    }

    .form-group {
        &.message {
            grid-column: 1 / span 2;
        }
    }

    .submit {
        width: 244px;
    }
}

@media screen and (max-width: 764px) {
    .wrapper {
        padding: 44px 40px;
        border-radius: 0;
    }

    .section-wrap {
        padding: 0;
    }
}
@media screen and (max-width: 524px) {
    .wrapper {
        padding: 44px 24px;
    }

    .grid {
        grid-gap: 16px;
        grid-template-columns: auto;
    }
}
