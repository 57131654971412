@import '@/styles/mixins';

.overlay {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    background-color: var(--colors-mono-black-opacity-20);

    z-index: 1000000;
    overflow: auto;
}

.popup-wrapper {
    width: fit-content;
    margin: auto;
}

.popup {
    width: 480px;

    border-radius: var(--radius-md);
    background: var(--white);

    padding: var(--sp32);

    position: relative;
    overflow: hidden;

    box-shadow: var(--shadow-popup);

    // TODO
    margin: 100px auto;

    &.large {
        width: 640px;

        padding: var(--sp40);
    }

    &.no-padding {
        padding: 0 !important;
    }
}

.close-button {
    @include flex-center;
    cursor: pointer;
    user-select: none;
    position: absolute;
    right: 20px;
    top: 20px;
    height: 20px;
    width: 20px;
    z-index: 4;

    opacity: 1;

    transition: 150ms ease-in-out;

    svg {
        stroke: var(--colors-gray-light-30);
        height: 16px;
        width: 16px;
    }

    &.hidden {
        visibility: hidden;
    }

    &:hover {
        background-color: var(--colors-gray-light-80);

        svg {
            stroke: var(--colors-gray-dark-60);
        }
    }
}

.animate-enter {
    opacity: 0;
}
.animate-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in-out;
}
.animate-exit {
    opacity: 1;
}
.animate-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
}

@media only screen and (max-width: 764px) {
    .overlay {
        @include flex-column;

        justify-content: flex-end;
        min-height: 100dvh;

        background-color: var(--colors-mono-black-opacity-40);

        overflow: auto;
    }

    .popup-wrapper {
        width: auto;
        margin: unset;

        border-radius: var(--radius-lg) var(--radius-lg) 0 0;

        overflow: auto;
    }

    .popup {
        width: 100% !important;

        border-radius: var(--radius-lg) var(--radius-lg) 0 0;

        margin: 60px 0 0;

        padding: var(--sp32) var(--sp40) var(--sp40);
    }
}

@media only screen and (max-width: 524px) {
    .popup {
        padding: var(--sp32) var(--sp24) var(--sp48);
    }
}
