@import '@/styles/mixins.scss';

.section {
    margin: 40px 0;

    &:last-of-type {
        margin-bottom: 80px;
    }
}

.wrapper {
    @include flex-center;
    flex-direction: column;
    gap: 32px;

    padding: 60px 160px;

    background: url('/images/flat-gradient.png');
    background-size: cover;
    background-position: center;

    border-radius: 16px;
}

.content-wrapper {
    @include flex-column;
    gap: 20px;
}

.title {
    @include headline-1;
    color: white;

    margin: 0;
    text-align: center;
}

.description {
    @include text-L;
    color: white;
    text-align: center;
}

.button {
    width: fit-content;
    margin: auto;
}

@media screen and (max-width: 1280px) {
    .wrapper {
        padding: 48px 140px;
    }
}

@media screen and (max-width: 960px) {
    .wrapper {
        padding: 48px 100px;
    }
}

@media screen and (max-width: 764px) {
    .section-wrap {
        padding: 0;
    }

    .wrapper {
        padding: 48px 40px;
        border-radius: 0;

        gap: 24px;
    }

    .content-wrapper {
        gap: 16px;
    }
}

@media screen and (max-width: 524px) {
    .wrapper {
        padding: 48px 24px;
    }
}
