@import '@/styles/mixins.scss';

.wrapper {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 26px));
    gap: 52px;

    opacity: 0.01;

    transition: 150ms ease-out;

    &.enter {
        opacity: 1;
    }
}

.left {
    @include flex-column;
    justify-content: space-between;
}

.content {
    @include flex-column;
    gap: 16px;
}

.title {
    @include headline-2;
    color: var(--colors-primary-dark-90, #020b18);
    margin: 0;
}

.description {
    @include text-L;
    color: var(--colors-gray-dark-40, #5b616b);
    margin: 0;

    p {
        margin: 0;
    }
}

.right {
    @include flex-column;
    gap: 52px;
}

.point {
    @include flex-row;
    gap: 20px;
    opacity: 0.01;
    transform: translateX(40px);

    transition: 150ms ease-in-out;

    &.enter {
        opacity: 1;
        transform: translateX(0);
    }
}

.number {
    @include flex-center;
    @include headline-5;
    display: flex;
    flex-shrink: 0;
    width: 36px;
    height: 36px;

    border-radius: 100px;
    background: var(--colors-primary-light-90, #e8f1fd);

    color: var(--colors-primary-dark-0, #1570ef);
}

.point-wrap {
    @include flex-column;
    gap: 12px;
    padding: 4px 0;
}

.point-title {
    @include headline-5;
    color: var(--colors-primary-dark-0, #1570ef);
    margin: 0;
}

.point-text {
    @include text-L;
    color: var(--colors-gray-dark-30, #6a717d);
    margin: 0;
}

.button {
    width: min-content;
    box-shadow: 0 0 0 4px var(--colors-primary-light-70, #b9d4fa), 0 0 0 8px var(--colors-primary-light-90, #b9d4fa) !important;

    transition: 150ms ease-in-out;

    opacity: 0.01;
    padding: 10px 20px;

    margin-top: 40px;

    &:hover {
        box-shadow: 0 0 0 5px var(--colors-primary-light-70, #b9d4fa),
            0 0 0 10px var(--colors-primary-light-90, #b9d4fa) !important;
    }

    &.enter {
        opacity: 1;
        animation: expandRetraction 600ms cubic-bezier(0.51, 0.92, 0.24, 1.15);
        animation-delay: 200ms;
    }
}

.mobile-button {
    display: none;
}

@keyframes expandRetraction {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

@media screen and (max-width: 1024px) {
    .wrapper {
        @include flex-column;
        gap: 40px;
    }

    .desktop-button {
        display: none;
    }

    .mobile-button {
        display: flex;
    }
}
@media screen and (max-width: 524px) {
    .mobile-button {
        width: 100%;
        display: flex;
    }
}
