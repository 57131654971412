@import '@/styles/mixins.scss';

.section-wrap {
    @include flex-column;
    gap: 52px;
    align-items: center;
}

.image-desc {
    @include text-L;
    color: var(--colors-gray-dark-40);
    margin: 0;
    text-align: center;
}
