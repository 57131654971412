@import '@/styles/mixins.scss';

.button {
    @include cta-primary;
    @include flex-center;
    display: inline-flex;

    height: 42px;
    padding: 11px 19px;
    background: var(--colors-primary);

    color: var(--white);
    text-align: center;
    white-space: nowrap;
    text-decoration: none;
    line-height: 38px;

    user-select: none;
    cursor: pointer;

    border: 1px solid transparent;
    border-radius: 100px;

    transition: 150ms ease-out;

    gap: 8px;

    &.square {
        padding: 12px 24px;
        border-radius: 8px;
    }

    &.blue-secondary {
        padding: 12px 20px;
        background: var(--colors-primary-light-95);
        color: var(--colors-primary);

        &:hover {
            background: var(--colors-primary-light-90);
        }
    }

    .icon {
        color: white;
    }

    &:hover {
        background: var(--colors-primary-dark-10);
    }

    &:active {
        background: var(--colors-primary-dark-20);
    }

    &.disabled {
        border: 1px solid var(--colors-mono-black-opacity-5);
        background: var(--colors-gray-light-95);
        pointer-events: none;
        cursor: default;
    }

    &.secondary,
    &.white {
        background: var(--white);
        color: var(--colors-primary);

        .icon {
            color: var(--colors-primary);
        }

        &:hover {
            background: var(--colors-gray-light-95);
            box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
        }

        &:active {
            background: var(--white);
            box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
        }
    }

    &.text,
    &.primary-text {
        color: var(--colors-primary);
        background: transparent;
        box-shadow: none;

        padding: 4px 0;
        line-height: unset;

        .icon {
            color: var(--colors-primary);
        }

        &:hover {
            color: var(--colors-primary-dark-10);

            .icon {
                color: var(--colors-primary-dark-10);
            }
        }

        &:active {
            color: var(--colors-primary);
        }

        &.disabled {
            color: var(--colors-gray);
        }
    }

    &.left-icon {
        flex-direction: row-reverse;
    }
}

.icon {
    width: 16px;
    height: 16px;
}

.video-popup {
    width: 1080px !important;
    min-height: min-content !important;
}

@media only screen and (max-width: 1280px) {
    .video-popup {
        width: 900px !important;
    }
}

@media only screen and (max-width: 960px) {
    .video-popup {
        width: 650px !important;
    }
}
