@import '@/styles/mixins.scss';

.section-wrap {
    @include flex-column;
    gap: 40px;
    max-width: 1160px !important;
    position: relative;
}

.section-title {
    padding-top: 0 !important;
}

.faq-section {
    @include flex-column;
    gap: 60px;
}

.tab-nav {
    @include flex-row;
    padding: 6px;
    border-radius: 24px;
    border: 1px solid var(--colors-mono-black-opacity-5, rgba(0, 0, 0, 0.05));
    background: var(--colors-mono-white-opacity-100, #fff);
    width: fit-content;
    margin: 0 auto;
}

.key-wrap {
    @include headline-6;
    @include flex-center;
    color: var(--colors-gray-dark-20, #7a828f);
    padding: 6px 16px;
    border-radius: 50px;
    transition: all 0.2s ease;

    &:hover {
        cursor: pointer;
    }

    &.active {
        color: var(--colors-primary-light-90, #f0f6ff);
        background: var(--colors-primary, #1570ef);
    }
}

.question-wrap {
    display: none;

    &:hover {
        cursor: pointer;
    }

    &.active {
        display: block;
    }

    &:last-child {
        .hr {
            display: none;
        }
    }
}

.hr {
    width: 100%;
    height: var(--counter, 1px);
    background: var(--colors-mono-black-opacity-5, rgba(0, 0, 0, 0.05));
    margin: 32px 0;
}

.question-row {
    @include flex-row;
    gap: 16px;
}

.qa {
    @include flex-column;
}

.questions {
    @include flex-column;
    width: 100%;
}

.question {
    @include headline-3;
    color: var(--colors-primary-dark-90, #020b18);
    margin: 0;
}

.answer {
    @include text-L;
    color: var(--colors-gray-dark-40);
    margin: 16px 0 0 0;
}

.icon-wrap {
    @include flex-center;
    width: 24px;
    height: 24px;
}

.icon {
    width: 24px;
    height: 24px;
    stroke: var(--colors-primary);
}

/* starting ENTER animation */
.enter {
    transform: translateX(-30px);
    opacity: 0.01;
}

/* ending ENTER animation */
.enter-active {
    transition: 200ms ease-out;
    transform: translateX(0);
    opacity: 1;
}

/* starting EXIT animation */
.exit {
    opacity: 1;
}

/* ending EXIT animation */
.exit-active {
    transition: 200ms ease-out;
    opacity: 0.01;
}

.view-all {
    width: fit-content;
    margin: 0 auto;
}

@media screen and (max-width: 1280px) {
    .question {
        @include headline-4;
    }
}

@media screen and (max-width: 764px) {
    .section-wrap {
        gap: 40px;
    }

    .tab-nav {
        flex-wrap: wrap;
        justify-content: center;
        border: none;
    }
}
