@import '@/styles/mixins.scss';

.full-width {
    max-width: 100%;
    padding: 50px 0 120px;
    margin: 0;
}

.wrapper {
    max-width: 1440px;
    margin: 0 auto;
    padding: 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.top-content {
    @include flex-column;
    transform: translateX(-100px);
    opacity: 0.01;
    gap: 12px;

    p,
    h2 {
        margin: 0;
    }

    &.enter {
        opacity: 1;
        transform: translateX(0);
        transition: 150ms ease-out;
        transition-delay: 200ms;
    }
}

.button {
    opacity: 0.01;

    &.enter {
        opacity: 1;
        transition: 100ms cubic-bezier(0.51, 0.92, 0.24, 1), opacity 100ms cubic-bezier(0.51, 0.92, 0.24, 1.15) 200ms;
    }
}

.title {
    @include headline-2;
    color: var(--colors-primary);
}

.description {
    @include text-L;
    color: var(--colors-gray-dark-10);
}

.buttons {
    @include flex-row;
    gap: 12px;
}

.slider-wrapper {
    position: relative;
    height: 80px;
}

.slider {
    position: absolute;
    left: 0;
    display: flex;
    transition: 150ms ease-out;

    &.second {
        opacity: 0.01;

        &.enter {
            opacity: 1;
            transition-delay: 300ms;
        }
    }
    &.entered {
        transition-delay: 0ms !important;
    }
}

.slider-element {
    min-width: 235px;
    width: calc(100vw / 5.5);
    height: 80px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: 150ms ease-out;
    user-select: none;
    background-color: var(--white);

    &.hidden {
        opacity: 0.01;
        transform: scale(1.4);
    }

    &.enter {
        opacity: 1;
        transform: scale(1);
        transition: 150ms ease-out;
        transition-delay: 300ms;
    }
}

.slider-element-title {
    @include text-L;

    border-left: 2px solid var(--colors-primary-light-70);
    padding: 0 20px;
}

.slider-image-wrap {
    position: relative;
    transition: 150ms ease-out;
    border-left: 1px solid var(--colors-primary-light-90);
    padding: 0 20px 10px;

    svg {
        width: 24px;
        height: 24px;
        stroke: var(--colors-primary);
    }
}

@media screen and (max-width: 1280px) {
    .wrapper {
        gap: 32px;
    }
}

@media screen and (max-width: 960px) {
    .wrapper {
        flex-direction: column;
    }

    .top-content {
        align-items: center;
        text-align: center;
        justify-content: center;
    }

    .description,
    .title {
        text-align: center;
    }

    .buttons {
        margin: auto;
    }
}

@media screen and (max-width: 764px) {
    .wrapper {
        padding: 40px;
    }

    .full-width {
        gap: 60px;
        padding: 60px 0 100px;
    }

    .wrapper {
        margin: unset;
    }
}

@media screen and (max-width: 524px) {
    .wrapper {
        padding: 40px 24px;
    }
}
