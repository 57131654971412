@import '@/styles/mixins.scss';

.wrapper {
    @include flex-column;
    justify-content: center;
    align-items: center;
}

.cards {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 24px;
    margin-top: 40px;
}

@media screen and (max-width: 1280px) {
    .cards {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

@media screen and (max-width: 960px) {
    .cards {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}
@media screen and (max-width: 524px) {
    .cards {
        grid-template-columns: 1fr;
    }
}
