@import '@/styles/mixins.scss';

.single-select-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 12px;

    &.two-column {
        grid-template-columns: repeat(2, 1fr);
    }
}

.option-input {
    display: none;

    &:checked + .option-label {
        color: var(--colors-primary);

        border-color: var(--colors-primary-light-20);
        border-width: 2px;

        // Adjust padding for extra border width.
        padding: 11px 15px;
    }
}

.option-label {
    @include subtitle-S;
    @include flex-center;
    padding: 12px 16px;
    gap: 8px;

    border-radius: 8px;
    background: var(--colors-mono-white-opacity-100);
    cursor: pointer;

    transition: 100ms ease-in-out border-color;

    border-style: solid;
    border-width: 1px;
    border-color: var(--colors-mono-black-opacity-5);

    color: var(--colors-gray-dark-30);
    user-select: none;
    text-align: center;

    &:hover {
        // Adjust padding for extra border width.
        padding: 11px 15px;

        border-color: var(--colors-primary-light-50);
        border-width: 2px;

        color: var(--colors-primary-light-20);
    }
}

@media only screen and (max-width: 764px) {
    .single-choice-wrapper.two-column {
        grid-template-columns: 1fr;
    }
}
