@import '@/styles/mixins.scss';

.section-wrap {
    opacity: 0.01;
    transform: translateY(60px);

    padding: 0;

    &.enter {
        opacity: 1;
        transform: translateY(0px);
        transition: 150ms ease-out;
    }

    &.first-section {
        padding-top: 0;
    }
}

.section-title {
    margin: 0 auto 60px auto !important;
}

.gradient-end {
    position: absolute;
    top: 350px;
}

.wrapper {
    @include flex-column;
    padding: 80px;
    gap: 24px;

    border-radius: 16px;
    border: 1px solid var(--colors-mono-black-opacity-5, rgba(0, 0, 0, 0.05));
    background: var(--colors-mono-white-opacity-100, #fff);
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.05);
}

.title,
.reset-button {
    @include headline-6;
    color: var(--colors-primary);
}

.reset-button {
    @include flex-center;
    cursor: pointer;
    width: fit-content;

    gap: 8px;

    svg {
        width: 16px;
        height: 16px;
        stroke: var(--colors-primary);
    }

    &.mobile {
        display: none;
    }
}

.question-wrapper {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 26px));
    gap: 52px;

    min-height: 423px;

    &.sub-question {
        min-height: 0;
    }
}

.left {
    @include flex-column;
    justify-content: space-between;
}

.content {
    @include flex-column;
    gap: 16px;

    .line {
        margin: 8px 0;
    }
}

.main-title {
    @include headline-2;
    color: var(--colors-primary-dark-90, #020b18);
    margin: 0;
}

.description {
    @include text-M;
    color: var(--colors-gray-dark-40, #5b616b);
    margin: 0;

    &.disclaimer {
        font-size: 14px;
        font-style: italic;
        font-weight: 300;
        line-height: 150%; /* 21px */
    }

    p {
        margin: 0;
    }
}

.right {
    @include flex-column;
    gap: 52px;
}

.single-select-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 24px;
}

.option-input {
    display: none;

    &:checked + .option-label {
        border-color: var(--colors-primary);

        &:hover {
            border-width: 2px;
        }

        .option-symbol {
            background: var(--colors-primary-light-90, #e8f1fd);
            border: 1px solid var(--colors-primary);

            .dot {
                width: 8px;
                height: 8px;
                border-radius: 4px;

                background: var(--colors-primary);
            }
        }
    }
}

.option-label {
    @include flex-center;
    padding: 24px;
    gap: 8px;

    border-radius: 16px;
    background: var(--colors-mono-white-opacity-100, #fff);
    cursor: pointer;

    transition: 100ms ease-in-out border-color;

    border-style: solid;
    border-width: 1px;
    border-color: var(--colors-mono-black-opacity-10, rgba(0, 0, 0, 0.1));

    user-select: none;

    &:hover {
        padding: 23px;
        border-color: var(--colors-primary-light-50);
        border-width: 2px;
    }
}

.option-symbol {
    @include flex-center;

    width: 16px;
    height: 16px;

    flex-shrink: 0;

    border-radius: 8px;
    border: 1px solid var(--colors-gray-light-70, #e0e3e8);
    background: var(--colors-mono-white-opacity-100, #fff);
}

.option-name {
    @include subtitle-M;
    color: var(--colors-gray-dark-50, #4c515a);

    flex-grow: 1;
}

.navigation-buttons {
    @include flex-row;
    gap: 16px;

    align-items: flex-end;
}

.button {
    @include cta-primary;
    @include flex-center;

    line-height: unset;

    height: 44px;
    padding: 10px 32px;
    border-radius: 32px;

    cursor: pointer;
    outline: none;

    transition: 150ms ease-in-out;

    &:disabled {
        color: var(--colors-gray-light-50) !important;
        border: 1px solid var(--colors-mono-black-opacity-5, rgba(0, 0, 0, 0.05));
        background: var(--colors-gray-light-95, #fafafb);
        box-shadow: none !important;

        cursor: default;

        &:hover {
            color: var(--colors-gray-light-50) !important;
            border: 1px solid var(--colors-mono-black-opacity-5, rgba(0, 0, 0, 0.05));
            background: var(--colors-gray-light-95, #fafafb);
            box-shadow: none !important;
        }
    }
}

.primary-button {
    color: var(--white);
    background: var(--colors-primary);
    border: 1px solid var(--colors-primary);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);

    &:hover {
        background: var(--colors-primary-dark-10);
    }

    &:active {
        background: var(--colors-primary-dark-20);
    }
}

.cta-button {
    box-shadow: 0 0 0 4px var(--colors-primary-light-70, #b9d4fa), 0 0 0 8px var(--colors-primary-light-90, #b9d4fa) !important;

    transition: 150ms ease-in-out;

    &:hover {
        box-shadow: 0 0 0 5px var(--colors-primary-light-70, #b9d4fa),
            0 0 0 10px var(--colors-primary-light-90, #b9d4fa) !important;
    }

    // opacity: 0.01;
    // &.enter {
    //     opacity: 1;
    //     animation: expandRetraction 600ms cubic-bezier(0.51, 0.92, 0.24, 1.15);
    //     animation-delay: 200ms;
    // }
}

@keyframes expandRetraction {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

.calculate-button {
    width: 200px;
    border-radius: 8px;
    margin-left: auto;
}

.split-wrap {
    @include flex-row;
    gap: 52px;

    margin-top: 16px;
}

.vertical-line {
    width: 1px;
    background: var(--colors-mono-black-opacity-10);

    flex-shrink: 0;
}
.horizontal-line {
    height: 1px;
    background: var(--colors-mono-black-opacity-10);

    flex-shrink: 0;
}

.content-line {
    @include caption-M-upper;
    @include flex-center;
    gap: 20px;

    color: var(--colors-gray);

    .horizontal-line {
        flex-grow: 1;
    }
}

.questions {
    @include flex-column;
    gap: 32px;

    &.excess {
        padding: 32px;
        border-radius: 8px;
        border: 1px solid var(--colors-gray-light-80);
        background: var(--colors-mono-white-opacity-100);
        box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.05), 0px 12px 16px -4px rgba(16, 24, 40, 0.1);
    }
}

.inline-question-wrapper {
    @include flex-row;
    gap: 40px;
}

.question-content {
    @include flex-column;

    flex-grow: 1;
    gap: 12px;
}

.question-title {
    @include headline-5;
    color: var(--colors-primary-dark-80, #041630);
}

.question-description {
    @include text-M;
    color: var(--colors-gray-dark-40, #5b616b);
    margin: 0;
}

.input-wrapper {
    @include subtitle-M;
    width: 200px;

    flex-shrink: 0;
    position: relative;
    display: block;

    input {
        padding-left: 24px !important;
    }

    span {
        color: var(--colors-gray-light-30);
        font-weight: 400;
        position: absolute;
        left: 12px;
        top: 0 !important;
        padding-bottom: 20px;
        line-height: 46px;
    }
}

.box {
    display: flex;
    width: 440px;
    height: fit-content;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 8px;

    border: 1px solid var(--colors-primary-light-90);
    background: var(--colors-primary-light-95);

    flex-shrink: 0;

    p {
        @include subtitle-S;
        color: var(--colors-gray-dark-30);

        margin: 0;
    }

    .box-icon {
        width: 20px;
        height: 20px;
        stroke: var(--colors-primary);
    }

    &.green {
        border: 1px solid var(--colors-success-light-90);
        background: var(--colors-success-light-95);

        .box-icon {
            stroke: var(--colors-success);
        }
    }

    &.orange {
        border: 1px solid var(--colors-warning-light-90);
        background: var(--colors-warning-light-95);

        .box-icon {
            stroke: var(--colors-warning);
        }
    }

    .calculate-button {
        width: 100%;
    }
}

.box-title {
    @include headline-3;
    color: var(--colors-primary-dark-90, #020b18);
}

.summary {
    @include flex-column;
    gap: 24px;
    height: fit-content;
    padding: 32px;
    border-radius: 16px;
    border: 1px solid var(--colors-gray-light-80);
    background: var(--colors-mono-white-opacity-100);
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.05), 0px 12px 16px -4px rgba(16, 24, 40, 0.1);

    flex-grow: 1;
}

.summary-top {
    @include flex-row;
    justify-content: space-between;
}

.summary-tag {
    @include subtitle-S;
    @include flex-center;
    padding: 4px 12px;

    border-radius: 16px;
    background: var(--colors-gray-light-95);
    mix-blend-mode: multiply;
}

.summary-results {
    @include flex-column;

    &.expandable {
        border: 2px solid var(--colors-gray-light-95);
        border-radius: 8px;

        &:hover {
            border: 2px solid var(--colors-primary-light-70);
        }
    }

    &.main-year-excess {
        border-radius: 8px;
        background-color: var(--colors-gray-light-95);
    }
}

.summary-wrap {
    @include flex-column;

    gap: 12px;

    &.excess {
        padding: 0 20px 16px;
    }
}

.summary-row {
    @include flex-row;
    justify-content: space-between;

    gap: 24px;

    &.standard {
        padding: 0 0 16px;
    }

    &.expandable {
        cursor: pointer;
    }
    &.padding {
        padding: 16px 20px;
    }
}

.summary-year {
    @include headline-6;
    color: var(--colors-primary-dark-90);
}

.summary-expand {
    stroke: var(--colors-gray);
    width: 24px;

    &.expanded {
        transform: rotate(180deg);
    }
}

.summary-label {
    @include subtitle-S;
    color: var(--colors-gray-dark-20);
}

.summary-value {
    @include subtitle-S;
    color: var(--colors-gray);

    flex-shrink: 0;
}

.tabs {
    @include flex-row;
    gap: 20px;
    padding: 0 8px;
}

.tab {
    @include text-M;
    color: var(--colors-gray);
    font-weight: 600;

    padding: 0 4px 18px;

    position: relative;

    cursor: pointer;
    user-select: none;

    white-space: nowrap;

    &.active {
        color: var(--colors-primary);

        .tab-bottom {
            position: absolute;
            width: 100%;
            height: 2px;

            bottom: 0;
            left: 0;
            right: 0;

            border-radius: 2px 2px 0 0;

            background: var(--colors-primary);
        }
    }
}

.totals-wrap {
    padding: 0 20px 16px;
}

@media screen and (max-width: 1600px) {
    .section-wrap {
        padding: 80px;
    }
}

@media screen and (max-width: 1440px) {
    .box {
        width: 380px;
    }
}

@media screen and (max-width: 1280px) {
    .wrapper {
        padding: 60px;
    }

    .section-wrap {
        padding: 60px;
    }

    .single-select-grid {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .inline-question-wrapper {
        flex-direction: column;
        gap: 24px;
    }

    .input-wrapper,
    .calculate-button {
        width: 100%;
    }

    .button-wrap {
        flex-direction: column-reverse;

        gap: 40px;

        .reset-button {
            width: 100%;
        }
    }
}

@media screen and (max-width: 1024px) {
    .single-select-grid {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .question-wrapper {
        @include flex-column;
    }

    .split-wrap {
        @include flex-column;
        gap: 40px;

        margin: 0;

        > .reset-button {
            margin: auto;
        }
    }

    .box {
        width: 100%;
    }

    .button-wrap {
        @include flex-center;
    }

    .reset-button {
        display: none;

        &.mobile {
            display: flex;
        }
    }
}

@media screen and (max-width: 764px) {
    .section-wrap {
        padding: 40px 0;
    }

    .wrapper {
        border: 0;
        border-radius: 0;

        padding: 40px;
        box-shadow: none;
    }

    .single-select-grid {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .summary {
        border: 0;
        box-shadow: none;
        border-radius: 0;
        padding: 32px 0 0;

        border-top: 1px solid var(--colors-gray-light-80);
    }
}

@media screen and (max-width: 524px) {
    .wrapper {
        padding: 20px;
    }

    .single-select-grid {
        grid-template-columns: minmax(0, 1fr);
    }

    .calculate-button {
        width: 100%;
        padding: 10px 16px;
    }

    .tabs {
        overflow: auto;
    }
}
