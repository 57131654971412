@import '@/styles/mixins.scss';

.wrapper {
    position: relative;

    transform: translateY(50px);
    opacity: 0.01;
    transition: 150ms ease-out;

    &.enter {
        opacity: 1;
        transform: translateY(0);
    }
}

.spacer {
    position: sticky;
    top: 0;

    background: white;
    height: 41px;
    width: 100%;

    z-index: 3;
}

.comparison-table {
    width: 100%;
    vertical-align: middle;
    text-align: center;
    border-collapse: collapse;

    thead {
        position: sticky;
        inset-block-start: 40px;
        inset-inline-end: 112px;

        background: white;

        box-shadow: 0 1px 0 0 var(--colors-mono-black-opacity-5);

        z-index: 3;
    }

    th {
        width: 22.33%;
        height: 76px;

        .taxd-column {
            @include flex-center;

            background-color: var(--colors-primary-light-95);
            border-radius: 17px 17px 0 0;

            height: 100%;
        }
    }

    tr {
        background: white;

        td,
        th {
            width: 22.33%;
            border-bottom: 1px solid var(--colors-mono-black-opacity-5);
        }

        th:first-child,
        td:first-child {
            width: 33%;

            border-right: 1px solid var(--colors-mono-black-opacity-5);
        }

        .taxd-column {
            background-color: var(--colors-primary-light-95);
        }

        &:last-child {
            td {
                border-bottom: 0;

                &.taxd-column {
                    border-radius: 0 0 17px 17px;
                }
            }
        }

        &.comparison-row {
            transform: translateX(-50px);
            opacity: 0.01;
            transition: 150ms ease-out;

            &.enter {
                opacity: 1;
                transform: translateX(0);
            }
        }
    }

    tbody {
        svg {
            width: 24px;
            height: 24px;
        }
    }
}

.header {
    @include flex-center;
    @include headline-6;
    gap: 8px;

    color: var(--colors-mono-black-opacity-90);

    .header-icon {
        height: 20px;
        width: 20px;
    }

    .header-logo {
        width: 72px;
    }
}

.feature {
    @include flex-center;
    flex-direction: column;

    gap: 7px;
    text-align: left;

    padding: 20px 24px 20px 0;

    span {
        @include headline-6;
        color: var(--colors-gray-dark-40);
        margin: 0;

        display: block;
        width: 100%;
    }

    p {
        @include text-S;
        color: var(--colors-gray-dark-40);
        margin: 0;
    }
}

@media screen and (max-width: 960px) {
    .comparison-table {
        tr {
            th,
            td {
                width: 17.66%;
            }

            th:first-child,
            td:first-child {
                width: 47%;
            }
        }
    }

    .accountant-icon {
        display: none;
    }
}

@media screen and (max-width: 764px) {
    .comparison-table {
        tr {
            th,
            td {
                min-width: 110px;
            }

            th:first-child,
            td:first-child {
                width: auto;
            }
        }
    }

    .accountant-icon {
        display: none;
    }
}
