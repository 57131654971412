@import '@/styles/mixins.scss';

.section-wrap {
    width: 720px;

    padding: 40px 0;
}

.title {
    @include headline-2;
    color: var(--colors-gray-dark-90);

    margin: 0;
    text-align: center;
}

.questions {
    @include flex-column;
    margin-top: 24px;
}

.question-wrap {
    @include flex-row;
    gap: 24px;
    cursor: pointer;

    padding: 20px 0;

    border-bottom: 1px solid var(--colors-mono-black-opacity-5);

    &:last-child {
        border-bottom: none;
    }
}

.icon-wrap {
    @include flex-center;
    width: 24px;
    height: 24px;
}

.icon {
    width: 24px;
    height: 24px;
    stroke: var(--colors-primary);
}

.content-wrapper {
    @include flex-column;
}

.faq-title {
    @include headline-5;
    color: var(--colors-primary-dark-80);

    user-select: none;
}

.faq-content {
    @include text-M;

    display: block;
    margin-top: 12px;

    color: var(--colors-gray-dark-40);

    p {
        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    strong {
        font-weight: 600;
    }
}

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 960px) {
    .section-wrap {
        width: 100%;
        max-width: 720px;
        padding: 40px;
    }
}

@media screen and (max-width: 764px) {
}

@media screen and (max-width: 524px) {
    .section-wrap {
        padding: 40px 24px;
    }
}
