@import '@/styles/mixins.scss';

.icon-text-box {
    display: grid;
    grid-template-columns: 24px 1fr;
    gap: 8px;
    transform: translateX(-10px);
    opacity: 0.01;

    &.enter {
        opacity: 1;
        transform: translateX(0);
        transition: 150ms ease-out;
    }

    span {
        align-self: center;
        @include text-S;
        color: #667085;
        margin: 0;
    }

    .resize {
        font-size: 16px;

        .black {
            color: var(--colors-primary-dark-90);
            font-size: 16px;
        }
    }
}
