@import '@/styles/mixins.scss';

.gradient-wrapper {
    // width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    opacity: 0;
    transition: opacity 150ms ease-out;

    background-image: url('/images/static-gradient.webp');
    background-size: cover;

    overflow: hidden;

    &.background {
        bottom: 0;
    }
}

.gradient-video {
    min-height: 100%;
    width: 100%;
    display: none;

    object-fit: cover;

    &.visible {
        display: block;
    }
}
