@import '@/styles/mixins.scss';

.wrapper {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: center;
    gap: 52px;
    transition: 150ms ease-out;

    padding: 0 70px !important;

    border &.right {
        > *:first-child {
            order: 1;
        }
    }

    &.left {
        .image-wrap {
            transform: translateX(80px);

            &.enter {
                opacity: 1;
                transform: translateX(0);
                transition: 150ms ease-out;
            }
        }

        > :nth-child(1) {
            grid-column: 2;
            grid-row: 1;
        }

        > :nth-child(2) {
            grid-column: 1;
            grid-row: 1;
        }
    }
}

.text-wrapper {
    @include flex-column;
    position: relative;

    gap: 24px;
    padding: 60px;
    border-radius: 16px;
    transition: ease-in 150ms;
}

.header-wrapper {
    @include flex-column;
    gap: 16px;
}

.buttons {
    @include flex-row;
    gap: 10px;
    margin-bottom: 24px;
}

.button {
    @include caption-L;
    opacity: 0.01;

    &.enter {
        opacity: 1;
        transition: 150ms ease-out;
    }

    &:hover {
        background: var(--white);

        span {
            color: var(--colors-primary);
        }
    }

    &.text-only {
        background: unset !important;

        &:hover {
            color: var(--white) !important;
        }
    }

    img {
        height: 10px;
    }
}

.image-background {
    @include flex-center;
    position: relative;

    background: linear-gradient(117deg, #d0e2fc 44.15%, #f3f8fe 100%);
    border-radius: 16px;
    max-width: 100%;
    padding: 80px 0 0 80px;
    min-height: 400px;
    height: 100%;

    overflow: hidden;
}

.image-wrap {
    position: absolute;
    height: 100%;
    width: 100%;
    transform: translateX(-80px);
    opacity: 0.01;

    bottom: -80px;
    right: -80px;

    &.enter {
        opacity: 1;
        transform: translateX(0);
        transition: 150ms ease-out;
    }
}

.image {
    object-fit: scale-down;
    max-height: 100%;
}

.icon-text-box {
    display: grid;
    grid-template-columns: 24px 1fr;
    gap: 8px;
    transform: translateX(-10px);
    opacity: 0.01;

    &.enter {
        opacity: 1;
        transform: translateX(0);
    }

    span {
        transition: ease-in 150ms;
        align-self: center;
        @include text-S;
        color: #667085;
        margin: 0;
    }

    .resize {
        font-size: 16px;

        .black {
            color: var(--colors-primary-dark-90);
            font-size: 16px;
        }
    }
}

.icon-wrapper {
    @include flex-center;
    width: 56px;
    height: 56px;
    background-color: var(--colors-primary-light-90);
    border-radius: 100px;
    opacity: 0.01;
    transform: scale(0);

    transition: ease-in 150ms;

    &.enter {
        opacity: 1;
        transform: scale(1);
    }
}

.icon {
    width: 24px;
    height: 24px;
    color: var(--colors-primary);

    transition: ease-in 150ms;
}

.bullet-icon {
    width: 20px;
    height: 20px;
    color: var(--colors-primary);

    transition: ease-in 150ms;
}

.title {
    @include headline-2;
    color: var(--colors-primary-dark-90);
    transform: translateX(-60px);
    opacity: 0.01;
    transition: ease-in 150ms;

    margin: 0;

    &.enter {
        opacity: 1;
        transform: translateX(0);
    }
}

.description {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    color: #667085;
    transform: translateX(-60px);
    opacity: 0.01;
    transition: ease-in 150ms;

    &.enter {
        opacity: 1;
        transform: translateX(0);
    }
}

@media screen and (max-width: 1280px) {
    .wrapper {
        padding: 0 60px !important;
    }

    .text-wrapper {
        padding: 40px;
    }

    .image-background {
        padding: 60px 0 0 60px;
    }

    .image-wrap {
        bottom: -60px;
        right: -60px;
    }
}
@media screen and (max-width: 960px) {
    .wrapper {
        @include flex-column;
        gap: 24px;
    }

    .text-wrapper {
        width: 100%;
        padding: 0;
    }

    .image-background {
        width: 100%;
        padding: 60px 0 0 60px;
    }

    .image-wrap {
        bottom: -60px;
        right: -60px;

        overflow: hidden;
    }

    .image {
        width: 100%;
        // object-fit: cover;
        object-position: left;
    }
}

@media screen and (min-width: 960px) {
    .text-wrapper {
        &:hover {
            .description,
            .title,
            .icon-text-box span,
            .button,
            .button img,
            .icon {
                color: var(--white, #fff);
            }

            .icon-wrapper {
                background-color: var(--colors-mono-white-opacity-20);
            }

            .bullet-icon {
                color: var(--colors-primary-light-50);
            }

            &::before {
                opacity: 1;
            }

            .button {
                background: var(--white);
                color: var(--colors-primary);

                &.text-only {
                    background: unset;
                    color: var(--white);

                    &:hover span {
                        color: white;
                    }
                }
            }
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            border-radius: 16px;

            background: linear-gradient(124deg, #1570ef 0%, #0d438f 100.05%), var(--white, #fff);
            transition: ease-in 150ms;

            opacity: 0;
        }
    }
}

@media screen and (max-width: 764px) {
    .wrapper {
        padding: 0 40px !important;
    }

    .image-background {
        padding: 40px 0 0 40px;
    }

    .image-wrap {
        bottom: -40px;
        right: -40px;

        overflow: hidden;
    }

    .image {
        min-height: 400px;
    }

    .buttons {
        margin-top: 0;
    }
}

@media screen and (max-width: 524px) {
    .wrapper {
        padding: 0 24px !important;
    }
    .text-wrapper {
        padding: unset;
    }

    .image-background {
        padding: 30px 0 0 30px;
        min-height: 220px;
    }

    .image-wrap {
        bottom: -30px;
        right: -30px;

        overflow: hidden;
    }

    .image {
        min-height: 220px;
    }
}
